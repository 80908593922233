import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  datoApi,
  defaultSettings,
  getApiSettingsFromHost,
} from '@hultafors/snickers/api';
import {
  GlobalFields,
  PageNotFoundFragment,
  PageNotFoundRecord,
  PageProps,
} from '@hultafors/snickers/types';

const Page = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Page),
);
const NotFoundContent = dynamic(() =>
  import('@hultafors/snickers/components').then(
    (mod) => mod.NotFoundPageContent,
  ),
);

export interface PageNotFoundProps extends PageProps {
  content: PageNotFoundFragment;
}

export const getStaticProps: GetStaticProps<PageNotFoundProps> = async () => {
  try {
    // Fetch default locale data
    const settings = getApiSettingsFromHost(defaultSettings.hostname);
    const dato = datoApi(settings);

    // Fetch page content
    const {
      data: { pageNotFound, ...globalFields },
    } = await dato.getPageNotFound();

    const props: PageNotFoundProps = {
      content: (pageNotFound as PageNotFoundRecord) || null,
      ...(globalFields as GlobalFields),
      settings,
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      props: {} as PageNotFoundProps,
    };
  }
};

const Custom404: NextPage<PageNotFoundProps> = ({ content, settings }) => {
  return (
    <Page>
      <NotFoundContent content={content} settings={settings} />
    </Page>
  );
};

export default Custom404;
